
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBPBHPGt0S9Ahel7UW1yt7SEm2LiekMorc",
  authDomain: "ecommers-2e7b5.firebaseapp.com",
  projectId: "ecommers-2e7b5",
  storageBucket: "ecommers-2e7b5.appspot.com",
  messagingSenderId: "89293078387",
  appId: "1:89293078387:web:4e4a28a343fc6e78fa0f06"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db