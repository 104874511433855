<template>
    <button class="basebutton">
        <slot></slot>
    </button>
</template>


<style scoped>

    button{
         background: rgb(67, 89, 99);
         padding: 10px;
         border:0px;
         border-radius: 5px;
         font-size: 18px;
         font-weight: bold;
         color: rgba(255, 255, 255, 0.8);
    }

    button:hover{
        cursor: pointer;
        background: rgb(67, 89, 99,0.9);
    }

</style>

