<template>

    <div v-if="loading">
        <img class="load" src="../assets/Spin.png" alt="">
    </div>
    <ul v-else>
        <Products v-for="product in $store.state.products"
        :key="product.title"
        :id="product.id"
        :title="product.title"
        :price="product.price"
        :image="product.image"
        />
    </ul>
</template>


<script>
import Products from '../components/TheProducts.vue'

export default {
    components:{
        Products
    },
    data(){
        return{
            loading:'true'
        }
    },
    async mounted() {
            this.loading = true;

            try {
                // Fetch products from the backend
                const response = await fetch('https://razorpayordercreate.onrender.com/products');
                if (!response.ok) {
                throw new Error('Failed to fetch products');
                }
                const data = await response.json();
                // Update Vuex store with the fetched products
                this.$store.state.products = data.products;

                console.log('User ID:', this.$store.state.userid);
            } catch (error) {
                console.error('Error loading products:', error);
            } finally {
                this.loading = false;
            }
        }

}

</script>




<style scoped>
ul{
    margin-top:50px;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    row-gap: 45px;
}

.load{
    width: 100px;
    margin-top:30vh ;
}

</style>