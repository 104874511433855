<template>

  <div class="header">
    <router-link to="/" class="main">
    <div class="title">
      <!-- <img class="logo" src="../assets/logo.png" alt=""> -->
      <!-- <h1>𝑻𝒆𝒂𝒌𝒘𝒐𝒐𝒅 𝑭𝒖𝒓𝒏𝒊𝒕𝒖𝒓𝒆 𝑷𝒐𝒊𝒏𝒕</h1> -->
    <h1>x x x</h1>
    </div>
    </router-link>
    <div class="search">
      <input type="text" placeholder="search">
      <div class="icon">
      <img class="sear" src="../assets/search.svg" alt="">
      </div>
    </div>
    <div class="items">
      <div>
        <router-link to="/">
          <h2>Home</h2>
        </router-link>
      </div>
      <div v-if="$store.state.loggedin" >
          <button @click="toggleprofile" @blur="profile" class="pointer profile" >
            <img class="pr-car" src="../assets/user-solid.svg" alt="">
            <transition name="pro">
            <div v-if="isprofile" class="profile-card">
              <div class="tirangle"></div>
              <router-link to="/profile">Profile</router-link>
              <router-link to="/orders">Orders</router-link>
              <p @click="logout">Logout</p>
            </div>
            </transition>
            </button>
      </div>
      <div v-if="$store.state.loggedin" class="pointer">
        <router-link to="/cart">
          <img class="pr-car car" src="../assets/cart-shopping-solid.svg" alt="">
        </router-link>
      </div>
      <div v-else>
        <router-link to="/login">
        <button class="pointer profile">
          <img class="pr-car" src="../assets/user-solid.svg" alt="">
        </button>
        </router-link>
      </div>
    </div>
  </div>
</template>


<script>
import { getAuth, signOut } from "firebase/auth";

export default {
  data(){
    return{
      isprofile:''
    }
  },
  methods:{
    profile(){
       this.isprofile=false
    },
        toggleprofile(){
          this.isprofile=!this.isprofile
        },
        logout(){

            const auth = getAuth();
            signOut(auth).then(() => {
              // Sign-out successful.
            }).catch((error) => {
              console.log(error.message)
              // An error happened.
            });
        }
  },
  mounted(){
    
  }
}
</script>


<style scoped>

.icon{
  width: 45px;
  height: 47px;
  border: 1px solid;
  border-left:0.5px ;
  border-radius: 0px 30px 30px 00px;
  display: flex;
  justify-content: center;
}

.icon:hover{
  cursor: pointer;
}

.search{
  display: flex;
}

.sear{
  width: 25px;
}

input{
  width: 500px;
  height: 45px;
  border-radius: 30px 0px 0px 30px;
  border-right:0.5px ;
  border: 1px solid;
  text-indent: 20px;
  font-size: 20px;
}

.pro-enter-active{
  animation: profile-enter .3s ease-in;
}

.pro-leave-active{
  animation: profile-leave .3s ease-out;
}

@keyframes profile-enter {
  from{
    opacity: 0%;
  }
  to{
    opacity: 100%;
  }
}

@keyframes profile-leave {
  from{
    opacity: 100%;
  }
  to{
    opacity: 0%;
  }
}


.profile{
  position: relative;
}

.car{
  position: relative;
  top:2px;
}


.profile-card{
   width: 140px;
   height: 150px;
   background: rgb(227, 190, 190);
   display: flex;
   flex-direction: column;
   justify-content: center;
   row-gap: 20px;
   font-size: 20px;
   font-weight: 600;
   border-radius: 5px;
   position: absolute;
   top:53px;
   right:0px;
}   

.tirangle{
  border:10px solid transparent;
  border-bottom-color:rgb(227, 190, 190);
  position: absolute;
  top:-19px;
  right: 12px;
}

a{
  text-decoration: none;
  color: black;
}

 a:hover{
    color: rgba(0, 0, 0, 0.592);
    text-decoration:underline;
  }
 p:hover{
    color: rgba(0, 0, 0, 0.592);
    text-decoration:underline;

  }

.title{
  display: flex;
  align-items: center;
  margin-left: 20px;
  column-gap: 10px;
}

.header{
  height: 90px;
  background: rgba(238, 216, 216, 0.432);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: sticky; */
}

.items{
  width: 450px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

button{
  width: 45px;
  height: 45px;
  background: rgba(227, 190, 190, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
}




.pr-car{
  width: 30px;
  height: 30px;
  margin:8px 0px;
}

.logo{
  width:250px;
  margin:0px -60px;
  border-radius: 50%;
}

.pointer:hover{
  cursor: pointer;
}

h1{
  font-size:2.5rem;
  margin: 0px 55px 0px -25px;

}

.main:hover{
  text-decoration: none;
  color: black;
}



</style>